<template>
  <div class="connector-field-select">
    <div class="form-group">
      <label class="form-group__label" :for="name">
        <slot name="label">
          {{ label }}
        </slot>
      </label>
      <Selector :id="name" v-model:value="value" :options="items" :can-clear="false" mode="single" />
      <span v-if="errorMessage" class="form-group__error-label">{{ errorMessage }}</span>
    </div>
  </div>
</template>

<script setup>
import { useField } from 'vee-validate';
import { computed } from 'vue';
import Selector from '@/components/ui/Selector.vue';

const props = defineProps({
  inputType: {
    type: String,
    default: 'text',
  },
  label: {
    type: String,
    default: () => undefined,
  },
  items: {
    type: Array,
    default: () => [],
  },
  name: {
    type: String,
    required: true,
  },
});

const { value, errorMessage } = useField(computed(() => props.name));
</script>
