import { LocationType, type Stop, type StopTime, type Trip } from '@/@types/gtfs';
import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import cloneDeep from 'clone-deep';
import { useStore } from 'vuex';
import api from '@/api';
import { clearEmptyValues, isDeepEqual } from '@/libs/helpers/objects';
import { Feature } from '@/features';
import type { MapStop } from '@/@types/mapbox';

export interface FeedStopTimes extends StopTime {
  type: FstType;
  inEdition?: boolean;
  inDeletion?: boolean;
  alreadyAddedInList?: boolean;
}

export enum FstType {
  // Deviation type used in back
  SHIFT = 'SHIFT', // existing stop in trip, with modified latlong
  REUSE = 'REUSE', // existing stop in gtfs, reused for this trip
  AD_HOC = 'AD_HOC', // non existing stop in gtfs, new name & latlong
  // Type for Front
  REGULAR = 'REGULAR',
  NEUTRALIZED = 'NEUTRALIZED', // not implemented yet
  CANCELED = 'CANCELED',
}

export interface DeviationStop {
  arrival_time: number;
  departure_time: number;
  stop_sequence: number;
  stop_lat: number;
  stop_lon: number;
  stop_name: string;
  stop_id: string;
  type: FstType;
}

export enum UpdateType {
  COMMENT = 'comment',
  DELAY = 'delay',
  TRIP_CANCELED = 'is_canceled',
  DO_NOT_SERVE = 'skipped_stop_time_seqs',
  STOP_INFO = 'stop_infos',
  SHAPE = 'shape',
  SPECIFIC_DELAYS = 'delays',
  TEMPORARY_STOPS = 'temporary_stops',
  DISPLACED_STOPS = 'displaced_stops', // For display purpose, does not exist in API
}

export enum DrawStep {
  SELECT_PATH,
  DRAW_SHAPE,
  DRAW_SHAPE_DONE,
}

export interface ApiTripUpdate extends TripUpdates {
  date: string;
  group_id: string;
  gtfs_id: string;
  trip_id: string;
}

export interface TripUpdates {
  comment: string;
  delay: number;
  is_canceled: boolean;
  skipped_stop_time_seqs: Array<number>;
  stop_infos: Array<StopInfo>;
  shape: Array<[number, number]>;
  delays: Array<Delay>;
  temporary_stops: Array<DeviationStop>;
}

export interface DisplayedTripUpdates extends Partial<TripUpdates> {
  displaced_stops?: Array<DeviationStop>;
}

export interface Delay {
  stop_sequence: number;
  delay: number;
}

export interface StopAwayFromShape {
  stopId: string;
  distanceThreshold: number;
  currentDistance: number;
}

export interface StopInfo {
  stop_sequence: number;
  information: string;
}

export const useTripUpdates = defineStore('tripUpdates', () => {
  // TODO -call to useStore to be replaced on the next store refactor
  const store = useStore();

  // #region context
  const gtfsId = ref<string>('');
  const groupId = computed<string>(() => store.getters.group._id);
  const trip = ref<Trip>({} as Trip);

  const allowDeviationFeature = ref<boolean>(false);

  const inAddManualStopMode = ref<Boolean>(false);
  const currentIndexNotValidated = ref<number | null>(null);
  const feedStopTimes = ref<Array<FeedStopTimes>>([]);

  const inStopTimeEdition = computed<boolean>(() => {
    return feedStopTimes.value.some(fst => fst.inEdition || fst.inDeletion);
  });
  const stopEditionRangeHighlight = ref<Array<number>>([]);

  watch(
    () => inStopTimeEdition,
    () => {
      if (!inStopTimeEdition.value) {
        stopEditionRangeHighlight.value = [];
      } else {
        const index = feedStopTimes.value.findIndex(fst => fst.inEdition || fst.inDeletion);
        if (index !== 0) stopEditionRangeHighlight.value.push(feedStopTimes.value[index - 1].stop_sequence);
        stopEditionRangeHighlight.value.push(feedStopTimes.value[index].stop_sequence);
        if (index !== feedStopTimes.value.length - 1)
          stopEditionRangeHighlight.value.push(feedStopTimes.value[index + 1].stop_sequence);
      }
    },
    { deep: true },
  );
  // #endregion

  // #region handle tripUpdate modifications
  const initialTripUpdate = ref<TripUpdates>({} as TripUpdates);
  const comment = ref<string>('');
  const delay = ref<number | null>(null);
  const isCanceled = ref<boolean>(false);
  const delays = ref<Delay[]>([]);
  const stopInfos = ref<StopInfo[]>([]);

  const updatedTripUpdate = computed<any>(() => {
    const skippedStopSequences = feedStopTimes.value
      .filter(fst => fst.type === FstType.CANCELED)
      .map(fst => fst.stop_sequence);

    let shapeToSend = editedShape.value;
    // Send a reset to shape element if edited shape is set to default shape
    if (
      JSON.stringify(editedShape.value) === JSON.stringify(initialTripShape.value) &&
      initialTripUpdate.value.shape
    )
      shapeToSend = undefined;
    const result = {
      comment: comment.value,
      delay: delay.value,
      is_canceled: isCanceled.value,
      stop_infos: stopInfos.value,
      skipped_stop_time_seqs: skippedStopSequences,
      shape: shapeToSend,
      delays: delays.value,
      temporary_stops: generateApiDeviations(feedStopTimes.value),
    };
    return result;
  });

  const hasTripUpdatesChanges = computed<boolean>(() => {
    return !isDeepEqual(clearEmptyValues(initialTripUpdate.value), clearEmptyValues(updatedTripUpdate.value));
  });
  // #endregion

  // #region modifyShape
  const inShapeEdition = ref<boolean>(false);
  const hasEditedShape = computed<boolean>(() => editedShape.value !== undefined && !inShapeEdition.value);
  const editedShape = ref<Array<[number, number]> | undefined>();
  const currentStep = ref<DrawStep>(DrawStep.SELECT_PATH);
  const initialTripShape = ref<Array<[number, number]>>([]);
  // #endregion

  // #region handle stops
  const allGtfsStops = ref<{ [key: string]: Stop }>({});

  const defaultTripStops = ref<Map<string, Stop>>(new Map());
  const reusedStops = ref<Map<string, Stop>>(new Map());
  const createdStops = ref<Map<string, Stop>>(new Map());
  const overridedStops = ref<Map<string, Stop>>(new Map());
  const stopInEdition = ref<Partial<Stop> | null>(null);

  const getAllStops = computed<Map<string, Stop>>(() => {
    const defaultStopTrip = cloneDeep(defaultTripStops.value);
    const stopInEditionMap = new Map();
    if (stopInEdition.value && stopInEdition.value.stop_lat && stopInEdition.value.stop_lon) {
      stopInEditionMap.set(stopInEdition.value.stop_id, stopInEdition.value);
    }

    return new Map([
      ...defaultStopTrip,
      ...reusedStops.value,
      ...createdStops.value,
      ...overridedStops.value,
      ...stopInEditionMap,
    ]);
  });

  const stopsAwayFromShape = ref<Array<StopAwayFromShape>>([]);

  const mapStops = computed<Array<MapStop>>(() => {
    const mapStopList: Array<MapStop> = [];
    feedStopTimes.value.forEach(fst => {
      mapStopList.push({
        id: fst.stop_id,
        highlight: false,
        unserved: fst.type === FstType.CANCELED,
        deviation: [FstType.AD_HOC, FstType.REUSE, FstType.SHIFT].includes(fst.type),
        editionHighlight:
          stopEditionRangeHighlight.value.length === 0
            ? true
            : stopEditionRangeHighlight.value.includes(fst.stop_sequence),
        stop_sequence: fst.stop_sequence,
      });
    });

    return mapStopList;
  });
  // #endregion

  // #region handle delays

  const timeStopCalculated = ref<Map<number, number>>(new Map());

  const timeInError = computed<Array<number>>(() => {
    const inError: Array<number> = [];

    const sortedMapByStopSequence = new Map(
      // sort by stop_sequence ASC
      [...timeStopCalculated.value.entries()].sort((a, b) =>
        parseInt(a.toString()) < parseInt(b.toString()) ? -1 : 1,
      ),
    );

    sortedMapByStopSequence.forEach((value, key) => {
      let currentMaxTs = 0;
      sortedMapByStopSequence.forEach((value1, key1) => {
        // check for previous stop if iterated ts is supperior to maxTs
        if (key1 < key && value1 > currentMaxTs) currentMaxTs = value1;
      });
      // if max ts found is supperior to currently iterated stop, add to inError
      if (currentMaxTs > value) inError.push(key);
    });
    return inError;
  });

  function setADelays(delay: number, stop_sequence: number) {
    const previousDelayValueIndex = delays.value.findIndex(d => d.stop_sequence === stop_sequence);
    if (previousDelayValueIndex !== -1) {
      if (delay === 0) delays.value.splice(previousDelayValueIndex, 1);
      else delays.value[previousDelayValueIndex].delay = delay;
    } else {
      if (delay !== 0) delays.value.push({ delay, stop_sequence });
    }
  }

  function setAnExtendedDelays(delay: number, stop_sequence: number) {
    feedStopTimes.value.forEach(fst => {
      if (fst.stop_sequence >= stop_sequence) {
        setADelays(delay, fst.stop_sequence);
      }
    });
  }

  function getADelaysByStopSequence(stop_sequence: number): Delay | undefined {
    return delays.value.find(d => d.stop_sequence === stop_sequence);
  }
  //  #endregion

  function $reset() {
    gtfsId.value = '';
    trip.value = {} as Trip;
    allowDeviationFeature.value = false;
    initialTripUpdate.value = {} as TripUpdates;
    comment.value = '';
    delay.value = null;
    isCanceled.value = false;
    delays.value = [];
    stopInfos.value = [];
    stopInEdition.value = null;
    allGtfsStops.value = {};
    defaultTripStops.value = new Map();
    reusedStops.value = new Map();
    createdStops.value = new Map();
    overridedStops.value = new Map();
    feedStopTimes.value = [];
    inAddManualStopMode.value = false;
    currentIndexNotValidated.value = null;
    inShapeEdition.value = false;
    editedShape.value = undefined;
    initialTripShape.value = [];
    timeStopCalculated.value = new Map();
  }

  async function initStore(currentGtfsId: string, tripId: string, tripUpdates: TripUpdates) {
    $reset();

    gtfsId.value = currentGtfsId;
    initialTripUpdate.value = cloneDeep(tripUpdates);

    // set isCanceled to false if not set
    if (!initialTripUpdate.value.is_canceled && initialTripUpdate.value.is_canceled !== false)
      initialTripUpdate.value.is_canceled = false;

    // Get authorization from group store
    allowDeviationFeature.value = store.getters.hasFeature(Feature.DEVIATION);

    comment.value = tripUpdates.comment;
    delay.value = tripUpdates.delay;
    isCanceled.value = tripUpdates.is_canceled || false;
    delays.value = tripUpdates.delays || [];
    stopInfos.value = tripUpdates.stop_infos;
    editedShape.value = tripUpdates.shape;

    const [tripFromApi, stopsFromStore] = await Promise.all([
      api.trips.getTripFromGtfs(groupId.value, currentGtfsId, tripId),
      store.dispatch('gtfs/getStopsMap', { gtfsId: currentGtfsId }),
    ]);

    trip.value = tripFromApi;
    allGtfsStops.value = stopsFromStore;
    // set default trip stops
    trip.value.stop_times?.forEach(st => {
      defaultTripStops.value.set(st.stop_id, stopsFromStore[st.stop_id]);
    });

    initFeedStopTimesAndStops(tripUpdates, trip.value.stop_times);
  }

  /**
   * convert trip updates to a FeedStopTimes list that can be used & displayed for front-end purpose
   * Also create new specific stops
   */
  function initFeedStopTimesAndStops(tripUpdates: TripUpdates, gtfsStopTimes: Array<StopTime>) {
    const fstList: Array<FeedStopTimes> = [];
    // Create default list & set regular/default type
    gtfsStopTimes.forEach(stopTime => {
      const type = tripUpdates.skipped_stop_time_seqs?.includes(stopTime.stop_sequence)
        ? FstType.CANCELED
        : FstType.REGULAR;
      const feedStopTime = { ...stopTime, type: type };
      fstList.push(feedStopTime);
    });

    // Handle temporary_stops
    tripUpdates.temporary_stops?.forEach(temporaryStop => {
      let toInsert: FeedStopTimes = {} as FeedStopTimes;
      switch (temporaryStop.type) {
        case FstType.SHIFT: {
          const newFst = convertDeviationToFst(temporaryStop);
          const feedIndex = fstList.findIndex(fst => fst.stop_sequence === temporaryStop.stop_sequence);
          // replace directly in feedStopTimes since it's an update
          fstList[feedIndex] = {
            ...fstList[feedIndex],
            ...newFst,
          };
          // updated stop :
          addOverridedStop(fstList[feedIndex].stop_id, temporaryStop.stop_lat, temporaryStop.stop_lon);
          break;
        }
        case FstType.REUSE: {
          toInsert = convertDeviationToFst(temporaryStop);
          addReusedStop(temporaryStop.stop_id);
          fstList.push(toInsert);
          break;
        }
        case FstType.AD_HOC:
          {
            toInsert = convertDeviationToFst(temporaryStop);

            addCreatedStop(
              temporaryStop.stop_id,
              temporaryStop.stop_name,
              temporaryStop.stop_lat,
              temporaryStop.stop_lon,
            );
            fstList.push(toInsert);
          }
          break;
        default:
          break;
      }
    });
    feedStopTimes.value = fstList.sort((a, b) => a.stop_sequence - b.stop_sequence);
  }

  function convertDeviationToFst(deviation: DeviationStop): FeedStopTimes {
    return {
      type: deviation.type,
      arrival_time: deviation.arrival_time,
      departure_time: deviation.departure_time,
      stop_sequence: deviation.stop_sequence,
      stop_id: deviation.stop_id,
    };
  }

  function convertFstAndStoptoDeviation(fst: FeedStopTimes, stop: Stop, stopSequence: number) {
    return {
      arrival_time: fst.arrival_time,
      departure_time: fst.departure_time,
      stop_sequence: stopSequence,
      stop_lat: stop.stop_lat,
      stop_lon: stop.stop_lon,
      stop_id: fst.stop_id,
      stop_name: stop.stop_name,
      type: fst.type,
    };
  }

  function addOverridedStop(stopId: string, lat: number, long: number) {
    const defaultStop = cloneDeep(defaultTripStops.value.get(stopId));
    if (defaultStop) {
      defaultStop.stop_lat = lat;
      defaultStop.stop_lon = long;
      overridedStops.value.set(stopId, defaultStop);
    }
  }

  function removeOverridedStop(stopId: string) {
    overridedStops.value.delete(stopId);
  }

  function addReusedStop(stopId: string) {
    const stop = cloneDeep(allGtfsStops.value?.[stopId]);
    if (stop) {
      reusedStops.value.set(stopId, stop);
    }
  }

  function removeReusedStop(stopId: string) {
    reusedStops.value.delete(stopId);
  }

  function addCreatedStop(stopId: string, name: string, lat: number, long: number) {
    const stop: Stop = {
      stop_id: stopId,
      stop_name: name,
      stop_lat: lat,
      stop_lon: long,
      location_type: LocationType.STOP,
    };
    createdStops.value.set(stopId, stop);
  }

  function removeCreatedStop(stopId: string) {
    createdStops.value.delete(stopId);
  }

  function generateApiDeviations(fst: FeedStopTimes[]): Array<DeviationStop> | undefined {
    const deviationFst = fst.filter(st => [FstType.AD_HOC, FstType.REUSE, FstType.SHIFT].includes(st.type));
    // Check if has temporary_stops types (not regular, neutralized or canceled stop)
    if (deviationFst.length === 0) return undefined;

    const temporaryStops: Array<DeviationStop> = [];

    let stopSequence = 0;
    deviationFst.forEach(dfst => {
      // handle stop sequence before 1 if multiple first stops has been added
      stopSequence = dfst.stop_sequence < 1 ? +(stopSequence + 0.1).toFixed(1) : dfst.stop_sequence;
      let concernedStop: Stop | undefined = undefined;
      switch (dfst.type) {
        case FstType.SHIFT:
          concernedStop = overridedStops.value.get(dfst.stop_id);
          break;
        case FstType.REUSE:
          concernedStop = reusedStops.value.get(dfst.stop_id);
          break;
        case FstType.AD_HOC:
          concernedStop = createdStops.value.get(dfst.stop_id);
          break;
        default:
          break;
      }
      if (concernedStop) {
        temporaryStops.push(convertFstAndStoptoDeviation(dfst, concernedStop, stopSequence));
      }
    });

    return temporaryStops;
  }

  // #region helper
  function getStopName(stopId: string): string {
    const stop = getAllStops.value.get(stopId);
    return stop ? stop.stop_name : '-';
  }
  // #endregion

  return {
    initStore,
    allowDeviationFeature,
    isCanceled,
    delay,
    updatedTripUpdate,
    allGtfsStops,
    hasTripUpdatesChanges,
    trip,
    defaultTripStops,
    getAllStops,
    feedStopTimes,
    currentIndexNotValidated,
    inAddManualStopMode,
    stopInEdition,
    overridedStops,
    inStopTimeEdition,
    addCreatedStop,
    addReusedStop,
    addOverridedStop,
    removeOverridedStop,
    removeReusedStop,
    removeCreatedStop,
    $reset,
    getStopName,
    inShapeEdition,
    editedShape,
    hasEditedShape,
    stopEditionRangeHighlight,
    currentStep,
    initialTripShape,
    stopsAwayFromShape,
    setAnExtendedDelays,
    setADelays,
    getADelaysByStopSequence,
    delays,
    timeStopCalculated,
    timeInError,
    mapStops,
  };
});
