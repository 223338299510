<script setup lang="ts">
import { useStore } from 'vuex';
import mapboxgl, { LngLatBounds, NavigationControl } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import MapboxLanguage from '@mapbox/mapbox-gl-language';
import MapboxTraffic from '@mapbox/mapbox-gl-traffic';
import merge from 'deepmerge';
import MapboxDepot from '@/components/map/MapboxDepot.vue';
import MapboxStops from '@/components/map/MapboxStops.vue';
import MapboxTrips from '@/components/map/MapboxTrips.vue';
import { computed, onMounted, ref, watch, type PropType, type Ref, useTemplateRef } from 'vue';
import type { MapStop, MapTrip, StopsOptions } from '@/@types/mapbox';
import i18n from '@/i18n';
import cloneDeep from 'clone-deep';
import { MapboxHelper } from './mapboxHelper';
import type { MapDepot } from '@/store-pinia/depots';

const store = useStore();

const BUS_ICON = new URL(`../../assets/img/bus-op.png`, import.meta.url).href;
const BEARING_ICON = new URL(`../../assets/img/bearing.png`, import.meta.url).href;
const CROSS_ICON = new URL(`../../assets/img/icons/cross-icon.png`, import.meta.url).href;
const DEVIATION_ICON = new URL(`../../assets/img/icons/deviation-map.png`, import.meta.url).href;
const SHAPE_ARROW_ICON = new URL(`../../assets/img/shape_arrow.png`, import.meta.url).href;
const URGENCY_ICON = new URL(`../../assets/img/urgency-icon.png`, import.meta.url).href;

const DEFAULT_STOPS_OPTIONS: Partial<StopsOptions> = {
  stationsMarkers: true,
  stopsMarkers: true,
  stopsZones: false,
  stopsBigMarkers: false,
};

const mapboxTrafficControl = new MapboxTraffic({ showTrafficButton: false });

const mapStyleOption: { [key: string]: string } = {
  map: 'mapbox://styles/mapbox/streets-v12',
  satellite: 'mapbox://styles/mapbox/satellite-streets-v12',
} as const;

const props = defineProps({
  gtfsId: {
    type: String,
    required: true,
  },
  stops: {
    type: Array as PropType<Array<MapStop>>,
    required: true,
  },
  stopsOptions: {
    type: Object as PropType<Partial<StopsOptions>>,
    default: () => ({}),
  },
  trips: {
    type: Array as PropType<Array<MapTrip>>,
    required: true,
  },
  bounds: {
    type: Object as PropType<LngLatBounds> | null,
    default: null,
  },
  center: {
    type: Object as PropType<[number, number]>,
    default: null,
  },
  displayTooltip: {
    type: Boolean,
    default: false,
  },
  fullScreenOption: {
    type: Boolean,
    default: true,
  },
  tripUpdateMode: {
    type: Boolean,
    default: false,
  },
  viewName: {
    type: String,
    default: null,
  },
  isOnTripModificationPage: {
    type: Boolean,
    default: false,
  },
  depots: {
    type: Array as PropType<Array<MapDepot>>,
    default: () => [],
  },
  depotEditing: {
    type: Boolean,
    default: false,
  },
});
mapboxgl.accessToken = import.meta.env.VITE_MAPBOX_API_KEY;

const emit = defineEmits([
  'mouseenter:trip',
  'mouseleave:trip',
  'mouseenter:stop',
  'mouseleave:stop',
  'update:bounds',
  'load',
  'click',
  'click:trip',
  'click:stop',
  'click:device',
  'click:stopTooltip',
]);

defineExpose({ switchStyle, onMapClick });

const mapLoaded = ref<Boolean>(false);
const tripsLoaded = ref<Boolean>(false);
const stopsLoaded = ref<Boolean>(false);
const depotsLoaded = ref<Boolean>(false);
const language = ref<MapboxLanguage>();
const mapInstance = ref<mapboxgl.Map | null>(null);
const debouncedEventTimer = ref<NodeJS.Timeout | null>(null);
const mapdiv = ref<HTMLElement>() as Ref<HTMLElement>;

const mapboxStopsComponent = useTemplateRef('mapboxStops');
const mapboxDepotsComponent = useTemplateRef('mapboxDepots');

const cStopsOptions = computed<StopsOptions>(() => merge(DEFAULT_STOPS_OPTIONS, props.stopsOptions));

const selectedMapStyle = computed({
  get() {
    return localStorage.getItem('map/style') || 'map';
  },

  set(style) {
    localStorage.setItem('map/style', style);
  },
});

watch(
  [() => mapLoaded.value, () => props.center, () => props.bounds],
  ([mapLoaded, center, bounds]) => {
    if (mapLoaded) {
      updateMapPosition(center, bounds);
    }
  },
  { immediate: true, deep: true },
);

watch(
  () => i18n.global.locale,
  () => {
    if (mapInstance.value && language.value) {
      mapInstance.value.setStyle(
        language.value.setLanguage(mapInstance.value.getStyle(), i18n.global.locale),
      );
    }
  },
);

// Watch for navbar width change to resize map
watch(
  () => store.state.leftNavBarClosed,
  () => {
    if (mapInstance.value) {
      mapInstance.value.resize();
    }
  },
);

onMounted(async () => {
  language.value = new MapboxLanguage({ defaultLanguage: i18n.global.locale });

  const map = new mapboxgl.Map({
    container: mapdiv.value,
    style: mapStyleOption[selectedMapStyle.value as keyof typeof mapStyleOption],
    center: [0, 0],
    zoom: 13,
    localFontFamily: "'Poppins', Arial, sans-serif",
    projection: {
      name: 'mercator',
      center: [0, 30],
      parallels: [30, 30],
    },
  });

  addOwnImagesToTheMap(map);

  const nav = new NavigationControl({ showCompass: false });
  if (props.fullScreenOption) {
    map.addControl(new mapboxgl.FullscreenControl(), 'top-right');
  }

  map.addControl(nav, 'top-right');

  map.addControl(language.value);

  await new Promise<void>(resolve => {
    map.on('load', () => {
      resolve();
    });
  });

  mapInstance.value = map;
  updateMapPosition();
  emit('load', { map });

  mapLoaded.value = true;

  map.resize();

  // handle click on map to deselect a device
  mapInstance.value.on('click', e => {
    if (!e.features) {
      onMapClick(e);
    }
  });
});

function onMapClick(event: mapboxgl.MapLayerMouseEvent, type?: string) {
  if (debouncedEventTimer.value) clearTimeout(debouncedEventTimer.value);

  // Capture `feature` here because it seems to
  // disappear in timeout function
  const feature = (event.features || [])[0];

  debouncedEventTimer.value = setTimeout(
    (event, type) => {
      const eventName = `click${type ? `:${type}` : ''}` as
        | 'mouseenter:trip'
        | 'mouseleave:trip'
        | 'mouseenter:stop'
        | 'mouseleave:stop'
        | 'update:bounds'
        | 'load'
        | 'click'
        | 'click:trip'
        | 'click:stop'
        | 'click:device'
        | 'click:stopTooltip';

      // Re-set `feature` in event data
      if (feature) {
        event.features = [feature];
      }

      emit(eventName, event);
    },
    100,
    event,
    type,
  );

  // For tooltips management on LiveMap
  mapboxStopsComponent.value?.resetHasStopBeenClicked();
  mapboxDepotsComponent.value?.resetHasDepotBeenClicked();
}

function updateMapPosition(
  center: [number, number] = props.center,
  bounds: mapboxgl.LngLatBounds = props.bounds,
) {
  if (mapInstance.value) {
    if (center) {
      if (mapInstance.value.getZoom() < 9) {
        mapInstance.value.setZoom(14);
      }
      mapInstance.value.panTo(center);
    } else if (bounds) {
      mapInstance.value.fitBounds(bounds, {
        padding: 40,
        animate: false,
      });
    }
  }
}
function onStopMouseEnter(event: mapboxgl.MapLayerMouseEvent) {
  if (mapInstance.value) mapInstance.value.getCanvasContainer().style.cursor = 'pointer';
  emit('mouseenter:stop', event);
}

function onStopMouseLeave(event: mapboxgl.MapLayerMouseEvent) {
  mapboxDepotsComponent.value?.resetHasDepotBeenClicked();
  if (mapInstance.value) mapInstance.value.getCanvasContainer().style.cursor = '';
  emit('mouseleave:stop', event);
}

function onDepotMouseLeave() {
  mapboxStopsComponent.value?.resetHasStopBeenClicked();
}

function switchStyle(style: string) {
  if (mapInstance.value) {
    // We copy layers and sources to re-add them after the map style has changed
    let layers = cloneDeep(mapInstance.value.getStyle().layers);
    // Remove mapbox layers to keep only ours
    layers = layers.filter((layer: any) => !layer.metadata);
    let sources = cloneDeep(mapInstance.value.getStyle().sources);

    selectedMapStyle.value = style;
    // The map style is changed here
    mapInstance.value.setStyle(mapStyleOption[selectedMapStyle.value as keyof typeof mapStyleOption], {
      diff: true,
    });

    setTimeout(() => {
      // Re-add our images
      addOwnImagesToTheMap(mapInstance.value);
      // Re-add our sources
      Object.entries(sources).forEach(([id, source]) => {
        if (!mapInstance.value?.getSource(id) && source.type === 'geojson')
          mapInstance.value?.addSource(id, source);
      });
      // Re-add our layers
      layers.forEach(layer => {
        if (!mapInstance.value?.getLayer(layer.id)) mapInstance.value?.addLayer(layer);
      });
      // Re-add traffic if necessary
      if (
        props.viewName !== null &&
        mapInstance.value &&
        MapboxHelper.getLayersOptionsFromLS(`settings.op.${props.viewName}.map.layerOptions`)?.traffic ===
          true
      ) {
        mapInstance.value.addControl(mapboxTrafficControl);
        mapboxTrafficControl.toggleTraffic();
      }
    }, 1000);
  }
}

function addOwnImagesToTheMap(map: mapboxgl.Map | null) {
  if (map) {
    MapboxHelper.addImage(map, BUS_ICON, 'busIcon');
    MapboxHelper.addImage(map, BEARING_ICON, 'bearingIcon');
    MapboxHelper.addImage(map, CROSS_ICON, 'crossIcon');
    MapboxHelper.addImage(map, DEVIATION_ICON, 'deviationIcon');
    MapboxHelper.addImage(map, SHAPE_ARROW_ICON, 'shape_arrow');
    MapboxHelper.addImage(map, URGENCY_ICON, 'urgencyIcon');
  }
}
</script>

<template>
  <div id="mapdiv" ref="mapdiv">
    <MapboxTrips
      v-if="mapLoaded && mapInstance"
      :gtfs-id="gtfsId"
      :trips="trips"
      :map="mapInstance"
      :trip-update-mode="tripUpdateMode"
      @click="onMapClick($event, 'trip')"
      @mouseenter="$emit('mouseenter:trip', $event)"
      @mouseleave="$emit('mouseleave:trip', $event)"
      @isLoaded="tripsLoaded = true"
    />

    <MapboxStops
      v-if="tripsLoaded && mapInstance"
      ref="mapboxStops"
      :gtfs-id="gtfsId"
      :options="cStopsOptions"
      :stops="stops"
      :trip-update-mode="tripUpdateMode"
      :display-tooltip="displayTooltip"
      :map="mapInstance"
      :is-on-trip-modification-page="isOnTripModificationPage"
      @click="onMapClick($event, 'stop')"
      @mouseenter="onStopMouseEnter($event)"
      @mouseleave="onStopMouseLeave($event)"
      @tooltipToggleStop="onMapClick($event, 'stopTooltip')"
      @update:bounds="$emit('update:bounds', $event)"
      @isLoaded="stopsLoaded = true"
    />

    <MapboxDepot
      v-if="tripsLoaded && mapInstance"
      ref="mapboxDepots"
      :gtfs-id="gtfsId"
      :depots="depots"
      :display-tooltip="displayTooltip"
      :map="mapInstance"
      :editing="depotEditing"
      @isLoaded="depotsLoaded = true"
      @mouseleave="onDepotMouseLeave"
    />

    <slot v-if="stopsLoaded && depotsLoaded" :map="mapInstance" :on-map-click="onMapClick" />
  </div>
</template>

<style lang="scss">
#mapdiv {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-family: $font-poppins;
}

// Hides info button and logo on mapbox
.mapboxgl-ctrl-logo,
.mapboxgl-ctrl-attrib-inner,
.mapboxgl-ctrl-attrib.mapboxgl-compact {
  display: none !important;
}

.mapboxgl-ctrl-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
  background-color: transparent;
  box-shadow: none !important;
}

.mapboxgl-ctrl-group button {
  border: 1px solid $border-variant !important;
  border-radius: 5px !important;
  background-color: $canvas;
}

.mapboxgl-ctrl button:not(:disabled):hover {
  background-color: rgb(255 255 255 / 80%);
}

.mapboxgl-ctrl-top-right .mapboxgl-ctrl {
  margin: 16px 16px 16px 0;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-fullscreen .mapboxgl-ctrl-icon {
  background-image: url('../../assets/img/icons/up-right-and-down-left-from-center.svg');
}

.mapboxgl-ctrl button.mapboxgl-ctrl-shrink .mapboxgl-ctrl-icon {
  background-image: url('../../assets/img/icons/down-left-and-up-right-to-center.svg');
}
</style>
