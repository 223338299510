<template>
  <div class="integrations">
    <template v-if="!!integrationType">
      <ActivateFeatureModal
        v-if="integrationType.contactUs"
        :title="$t(integrationType.labelKey)"
        @close="handleModalClose"
      />

      <ModalIntegrationConnector
        v-else
        :integration-type="integrationType"
        :model-value="integration"
        @update:modelValue="v => saveIntegration(v)"
        @close="handleModalClose"
      />
    </template>

    <ModalIntegrationConnectorDelete
      v-if="integrationTypeDelete"
      :integration-type="integrationTypeDelete"
      @close="handleDeleteConfirmationClose"
      @delete="handleDeleteConfirmationValidate"
    />

    <div v-if="activeIntegrations.length > 0" class="integrations__top-container">
      <div class="integrations__title">{{ $t('myIntegration') }}</div>
      <v-container fluid>
        <v-row>
          <v-col v-for="(activeIntegration, index) in activeIntegrations" :key="index">
            <IntegrationTypeCard
              v-if="activeIntegration.type"
              :key="activeIntegration.type.labelKey"
              class="integrations__integration_type_card"
              :value="activeIntegration.type"
              active
              @open="handleCardOpen($event, activeIntegration)"
              @delete="handleCardDelete"
            />
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div class="integrations__bottom-container">
      <div class="integrations__title">{{ $t('explore') }}</div>

      <div class="integrations__filters-area">
        <SearchBar
          ref="searchBar"
          :search-fields="['labelKey']"
          :search-list="inactiveIntegrationTypesLocal"
          id-key="labelKey"
          @filteredList="setShownInactiveIntegrations"
        />
        <div class="integrations__connector-filters">
          <ConnectorFilter
            v-for="filterItem in Filter"
            :key="filterItem"
            :value="filterItem"
            :active="activeFilter === filterItem"
            @toggleFilter="toggleFilter"
          />
        </div>
      </div>

      <v-container fluid>
        <v-row>
          <v-col v-for="(inactiveIntegrationType, index) in shownInactiveIntegrations" :key="index">
            <IntegrationTypeCard
              :key="inactiveIntegrationType.labelKey"
              :value="inactiveIntegrationType"
              @open="handleCardOpen"
            ></IntegrationTypeCard>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { POSITION, useToast } from 'vue-toastification';

import SearchBar from '@/components/common/SearchBar.vue';
import ModalIntegrationConnector from '@/pages/IntegrationsPage/ModalIntegrationConnector.vue';
import ActivateFeatureModal from '@/components/common/ActivateFeatureModal.vue';
import { CONNECTOR_CATEGORYS, Filter } from '@/store/integrations';

import ConnectorFilter from './ConnectorFilter.vue';
import ModalIntegrationConnectorDelete from './ModalIntegrationConnectorDelete.vue';
import DeletedConnectorToast from './DeletedConnectorToast.vue';
import IntegrationTypeCard from './IntegrationTypeCard.vue';
import SavedConnectorToast from './SavedConnectorToast.vue';

const { success, dismiss } = useToast();

const store = useStore();

const activeFilter = ref();
const integration = ref();
const integrationType = ref();
const integrationTypeDelete = ref();
const shownInactiveIntegrations = ref();
const searchBar = ref(null);
const inactiveIntegrationTypesLocal = ref([]);

const activeIntegrations = computed(() => {
  return store.getters['integrations/activeIntegrations'];
});

const inactiveIntegrationTypes = computed(() => {
  return store.getters['integrations/inactiveIntegrationTypes'];
});

function setShownInactiveIntegrations(list) {
  let activeList = list;
  if (activeFilter.value) {
    activeList = activeList.filter(connector =>
      CONNECTOR_CATEGORYS[activeFilter.value].includes(connector.labelKey),
    );
  }
  shownInactiveIntegrations.value = activeList;
}

watch(
  inactiveIntegrationTypes,
  value => {
    setShownInactiveIntegrations(value);
    inactiveIntegrationTypesLocal.value = value;
  },
  { immediate: true },
);

/**
 * @param {import('./IntegrationTypeCard.vue').OpenEvent} event
 * @param {import('@/store/integrations').Integration} integrationValue
 */
function handleCardOpen(event, integrationValue) {
  integrationType.value = event.value;
  integration.value = integrationValue;
}

function handleDeleteConfirmationClose() {
  integrationTypeDelete.value = undefined;
}

/**
 * @param {import('./IntegrationTypeCard.vue').DeleteEvent} event
 */
function handleCardDelete(event) {
  integrationTypeDelete.value = event.value;
}

function handleModalClose() {
  integrationType.value = undefined;
  integration.value = undefined;
}

async function handleDeleteConfirmationValidate() {
  const integrationType = integrationTypeDelete.value;
  integrationTypeDelete.value = undefined;

  await store.dispatch('integrations/deleteActiveIntegrationType', {
    value: integrationType,
  });
  const toastId = success(DeletedConnectorToast, {
    position: POSITION.BOTTOM_RIGHT,
    timeout: 5000,
    icon: 'fas fa-check',
  });
  // To remove when toast lib fix timeout error
  setTimeout(() => dismiss(toastId), 5000);
}

/**
 * @param {Integration} integration
 */
async function saveIntegration(integration) {
  await store.dispatch('integrations/saveIntegration', { value: integration });
  integrationType.value = undefined;

  const toastId = success(SavedConnectorToast, {
    position: POSITION.BOTTOM_RIGHT,
    timeout: 5000,
    icon: 'fas fa-check',
  });
  // To remove when toast lib fix timeout error
  setTimeout(() => dismiss(toastId), 5000);
}

function toggleFilter(filter) {
  searchBar.value.resetSearch();
  if (activeFilter.value === filter) {
    activeFilter.value = null;
  } else {
    activeFilter.value = filter;
  }
  setShownInactiveIntegrations(inactiveIntegrationTypes.value);
}
</script>

<style lang="scss">
.integrations {
  .v-container {
    padding: 16px 0;
  }

  .v-col {
    flex-grow: 0;
  }

  .table-search-bar {
    margin-left: 0;

    &__input {
      height: 36px;
    }
  }

  &__bottom-container {
    padding: $view-standard-padding;
  }

  &__connector-filters {
    display: flex;
  }

  &__filters-area {
    display: flex;
    gap: 20px;
    margin: 10px 0;
  }

  &__title {
    font-weight: $font-weight-semi-bold;
    font-size: 18px;
  }

  &__top-container {
    padding: $view-standard-padding;
    padding-bottom: 10px;
    border-bottom: 1px solid $border;
    background-color: $white;
  }
}
</style>

<i18n locale="fr">
{
  "myIntegration": "Mes intégrations",
  "myFeatures": "Mes fonctionnalités",
  "explore": "Explorer les intégrations",
}
</i18n>

<i18n locale="en">
{
  "myIntegration": "My integrations",
  "myFeatures": "My features",
  "explore": "Explore integrations",
}
</i18n>
