import { createAuth0 } from '@auth0/auth0-vue';
import env from '@/env';

const auth0 = createAuth0({
  auth0Client: {
    name: 'Op',
    version: '1.0.0',
  },
  domain: env.auth0.domain,
  clientId: env.auth0.clientId,
  authorizationParams: {
    redirect_uri: env.urls.app,
    audience: env.auth0.audience,
    prompt: 'login',
  },
});

export const untilAuthenticated = async (ms: number) => {
  try {
    await auth0.getAccessTokenSilently();
  } catch (e) {
    console.error(e);
    return;
  }
  while (auth0.isLoading.value) {
    // eslint-disable-next-line no-promise-executor-return
    await new Promise(resolve => setTimeout(resolve, ms));
  }
};

export default auth0 as any;
