<template>
  <div class="duty-list">
    <div class="duty-list__header">
      <div class="duty-list__header-left">
        <HeaderDatePicker
          v-model:value="selectedDate"
          :disabled="calendarDisabledDates"
          class="duty-list__header-datepicker"
        />
      </div>

      <div class="duty-list__header-right">
        <Btn type="secondary" @click="downloadDutyData">
          <font-awesome-icon icon="fa-download" />
          <span class="btn-text">{{ $t('download') }}</span>
        </Btn>
        <Btn type="primary" :route="{ name: GroupRoute.MESSAGE_INBOX }">
          <font-awesome-icon icon="fa-paper-plane" />
          <span class="btn-text">{{ $t('sendMessage') }}</span>
        </Btn>
      </div>
    </div>

    <DataGridVuetify
      v-if="dutyList.length > 0"
      ref="dataGrid"
      v-model:rendered-data-length="renderedDataLength"
      :title="$t('duties', { count: renderedDataLength })"
      :data="dutyList"
      :datagrid="datagrid"
      :loading="loading"
      :reload-on-refresh="!isToday"
    />

    <div v-else class="duty-list__no-data">
      <div>
        <div class="duty-list__no-data-text">
          {{ $t('noDuties') }}
        </div>
        <div class="duty-list__no-data-buttons">
          <Btn type="secondary" @click="goToPreviousDay">{{ $t('previousDay') }}</Btn>
          <Btn type="secondary" @click="goToNextDay">{{ $t('nextDay') }}</Btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import cloneDeep from 'clone-deep';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import DataGridVuetify from '@/components/Table/DataGridVuetify/index.vue';
import Btn from '@/components/ui/Btn.vue';
import HeaderDatePicker from '@/components/ui/HeaderDatepicker.vue';
import {
  dateGtfsFormatToObj,
  dateObjToGtfsFormat,
  getISODate,
  timestampFormatHHMM,
} from '@/libs/helpers/dates';
import { toCSV, triggerDownloadCSV } from '@/libs/csv';
import { GroupRoute } from '@/libs/routing';
import { ColumnKey, DutyStatus, getDatagrid } from '@/pages/DutyListPage/DutyList.conf.js';
import { NO_DATA } from '@/components/Table/DataGridVuetify/models/DataGrid.models';
import { useVehiclesStore } from '@/store-pinia/vehicles';

dayjs.extend(utc);

/** @type {number} */
const UPDATE_FREQUENCE = 60000;

/** @enum {string} */
const DutyMultiTagsType = {
  CLEANINGNESS: 'cleaningness',
  FUEL_LEVEL: 'fuelLevel',
};
export default {
  name: 'DutyList',

  components: { Btn, DataGridVuetify, HeaderDatePicker },

  props: {
    /** @type {import('vue').Prop<{date?: string}>} */
    query: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    ColumnKey,
    GroupRoute,
    vehiclesStore: useVehiclesStore(),

    /** @type {import('@/components/Table/DataGridVuetify/models/DataGrid.models').DataGrid} */
    datagrid: getDatagrid(),
    /** @type {Array<FormattedDuties>} */
    dutyList: [],
    /** @type {Boolean} */
    loading: true,
    /** @type {number} */
    renderedDataLength: null,
    /** @type {NodeJS.Timeout} */
    updateDuties: null,
    cleaningnessPossibleValues: [],
    fuelLevelPossibleValues: [],
  }),

  computed: {
    ...(() => {
      const mapping = {
        /** @return {{[deviceId: string]: import('@/store/devices').Device}} */
        devicesList: state => state.devices.list,
        /** @return {{[driverId: string]: import('@/store/drivers').Driver}} */
        driversList: state => state.drivers.list,
      };

      return /** @type {typeof mapping} */ (mapState(mapping));
    })(),

    /** @return {Dictionary<import('@/store-pinia/vehicles').Vehicle>*/
    vehiclesList() {
      return this.vehiclesStore.list;
    },

    /** @return {import('@/components/ui/HeaderDatepicker.vue').DisabledDates} */
    calendarDisabledDates() {
      const minDate = dayjs(new Date()).subtract(1, 'year').toDate();
      const maxDate = new Date();
      return {
        minDate,
        maxDate,
      };
    },

    /** @return {string} */
    dateGtfs() {
      return dateObjToGtfsFormat(this.selectedDate);
    },

    /** @return {import('@/store').Group} */
    group() {
      return this.$store.getters.group;
    },

    /** @return {boolean} */
    isToday() {
      return dateObjToGtfsFormat(this.selectedDate) === dateObjToGtfsFormat(new Date());
    },

    /** @return {Date} */
    selectedDate: {
      /** @return {Date} */
      get() {
        if (this.query.date) {
          const date = dayjs(this.query.date).hour(23).minute(59).second(59).utc().toDate();
          return date;
        }
        return new Date();
      },

      /** @param {Date} value */
      set(value) {
        this.$router.push({
          name: GroupRoute.DUTY_LIST,
          params: {
            groupId: this.group.group_id,
          },
          query: {
            date: getISODate(value),
          },
        });
      },
    },
  },

  watch: {
    dateGtfs: {
      immediate: true,
      handler() {
        this.loadDutyList();
      },
    },
  },

  beforeUnmount() {
    clearInterval(this.updateDuties);
  },

  async created() {
    this.cleaningnessPossibleValues = [
      { const: 0, title: this.$t('cleaningness.dirty'), color: 'danger' },
      { const: 0.5, title: this.$t('cleaningness.average'), color: 'warning' },
      { const: 1, title: this.$t('cleaningness.clean'), color: 'success' },
    ];
    this.fuelLevelPossibleValues = [
      { const: 0, title: this.$t('fuel.empty'), color: 'danger' },
      { const: 0.25, title: this.$t('fuel.low'), color: 'warning' },
      { const: 0.75, title: this.$t('fuel.high'), color: 'success' },
      { const: 1, title: this.$t('fuel.full'), color: 'success' },
    ];

    this.$store.dispatch('devices/getDevices');
    this.$store.dispatch('drivers/loadList');
    await this.vehiclesStore.loadList();

    this.loadDutyList();
    this.updateDutyList();
  },

  methods: {
    /**
     * Get data from dataGrid and create download
     */
    async downloadDutyData() {
      const result = this.$refs.dataGrid.exportData();
      if (result.data.length > 0) {
        // Iterate through data to format some properties
        result.data.map(item => {
          // Format to time if ts
          item.status = this.$t(item.status);
          return item;
        });

        // Create translation for headerRow
        const headerRow = [];
        result.translatedKeys.forEach(name => {
          headerRow.push(name);
        });

        // creating csv
        const data = [
          headerRow,
          ...result.data.map(row => Object.values(row).map(value => value?.toString() || '-')),
        ];
        const link = toCSV(data);
        // Trigger download
        triggerDownloadCSV(link, this.$t('dutyListDownloadName', [dateObjToGtfsFormat(this.selectedDate)]));
      }
    },

    formatDutyList() {
      /** @type {Array<import('@/store/activity-log').ActivityLogEntry>} */
      const entries = cloneDeep(this.$store.state.activityLog.entries);
      /** @type {Array<FormattedDuties>} */
      const formattedEntries = [];
      entries.forEach(entry => {
        /** @type {FormattedDuties} */
        const formattedEntry = entry;
        // Format data for dataGrid and export
        formattedEntry.device = this.devicesList[entry.device_id]?.name || entry.device_id;

        formattedEntry.startTime = timestampFormatHHMM(entry.check_in.ts, { tz: this.group.tz });

        formattedEntry.endTime = entry.checkout
          ? timestampFormatHHMM(entry.checkout?.ts, { tz: this.group.tz })
          : null;

        formattedEntry.status = entry.checkout ? this.$t(DutyStatus.OVER) : this.$t(DutyStatus.IN_PROGRESS);
        formattedEntry.unformattedStatus = entry.checkout ? DutyStatus.OVER : DutyStatus.IN_PROGRESS;

        formattedEntry.comments = this.formatCheckinChekoutData(entry, 'comments');
        formattedEntry.vehicleEmpty = this.formatCheckinChekoutData(entry, 'vehicle_empty');
        formattedEntry.vehicleEmptyLabels = formattedEntry.vehicleEmpty
          ? formattedEntry.vehicleEmpty.map(emptyData => {
              if (typeof emptyData !== 'boolean') return NO_DATA;
              return emptyData ? this.$t('yes') : this.$t('no');
            })
          : [NO_DATA, NO_DATA];
        formattedEntry.vehicleCleaningness = this.formatCheckinChekoutData(
          entry,
          'vehicle_cleanliness',
          DutyMultiTagsType.CLEANINGNESS,
        );

        formattedEntry.vehicleCleaningnessLabels = formattedEntry.vehicleCleaningness
          ? formattedEntry.vehicleCleaningness.map(cleanData => cleanData.title ?? NO_DATA)
          : [NO_DATA, NO_DATA];

        formattedEntry.vehicleFuelLevel = this.formatCheckinChekoutData(
          entry,
          'vehicle_fuel_level',
          DutyMultiTagsType.FUEL_LEVEL,
        );
        formattedEntry.vehicleFuelLevelLabels = formattedEntry.vehicleFuelLevel
          ? formattedEntry.vehicleFuelLevel.map(cleanData => cleanData.title ?? NO_DATA)
          : [NO_DATA, NO_DATA];

        // Format data for export
        formattedEntry.tripsFormatted = entry.trips.map(trip => trip.formatted_name).join(', ');
        formattedEntry.tripsWithLinks = entry.trips.map(trip => {
          return {
            text: trip.formatted_name,
            link: {
              name: GroupRoute.TRIP_DETAILED,
              params: { tripId: trip.id },
              query: { date: getISODate(dateGtfsFormatToObj(trip.start_date)) ?? null },
            },
          };
        });

        // Get drivers information from driver id
        if (this.driversList && entry.driver_id) {
          formattedEntry.driver = this.$store.getters['drivers/getDriverInfosById'](entry.driver_id);
        }
        // Get vehicles information from vehicle id
        if (this.vehiclesList && entry.vehicle_id) {
          formattedEntry.vehicle = this.vehiclesStore.getVehicleInfosById(entry.vehicle_id);
        }
        formattedEntries.push(formattedEntry);
      });

      this.dutyList = formattedEntries;
    },

    goToPreviousDay() {
      const date = new Date(this.selectedDate);
      date.setDate(date.getDate() - 1);
      this.selectedDate = date;
    },

    goToNextDay() {
      const date = new Date(this.selectedDate);
      date.setDate(date.getDate() + 1);
      this.selectedDate = date;
    },

    async loadDutyList() {
      this.loading = true;
      await this.$store.dispatch('activityLog/loadEntries', this.dateGtfs);
      this.formatDutyList();
      this.loading = false;
    },

    /** Get activity log every minute */
    updateDutyList() {
      // Regular updates only on current date
      if (this.isToday) {
        this.updateDuties = setInterval(() => {
          this.loadDutyList();
        }, UPDATE_FREQUENCE);
      }
    },
    /**
     * Create an array of value "checkin" & "checkout"
     * @param {import('@/store/activity-log').ActivityLogEntry} duty
     * @param {string} field
     * @param {string} [type]
     * @return {?[any, any] }
     */
    formatCheckinChekoutData(duty, field, type = null) {
      /** @type {[any, any]} */
      const values = [NO_DATA, NO_DATA];
      if (duty?.check_in?.checklist?.[field] != undefined) {
        if (type !== null) values[0] = this.getTagObjectByValue(duty.check_in.checklist[field], type);
        else values[0] = duty.check_in.checklist[field];
      }
      if (duty?.checkout?.checklist?.[field] != undefined) {
        if (type !== null) values[1] = this.getTagObjectByValue(duty.checkout.checklist[field], type);
        else values[1] = duty.checkout.checklist[field];
      }
      // if no data, return null to avoid useless display in datagrid
      if (values[0] === NO_DATA && values[1] === NO_DATA) return null;
      return values;
    },

    getTagObjectByValue(value, type) {
      if (value === null) return NO_DATA;
      const listValues =
        type === DutyMultiTagsType.CLEANINGNESS
          ? this.cleaningnessPossibleValues
          : this.fuelLevelPossibleValues;
      return listValues.find(possibleValue => possibleValue.const === value) ?? NO_DATA;
    },
  },
};

/**
 * @typedef {Object} FormattedDuties
 * @property {string} _id
 * @property {string} device
 * @property {string} startTime
 * @property {string} endTime
 * @property {string} status
 * @property {string} unformattedStatus
 * @property {[boolean, boolean]} vehicleEmpty
 * @property {string[]} vehicleEmptyLabels
 * @property {[object, object]} vehicleCleaningness
 * @property {string[]} vehicleCleaningnessLabels
 * @property {[object, object]} vehicleFuelLevel
 * @property {string[]} vehicleFuelLevelLabels
 * @property {[string, string]} comments
 * @property {string} tripsFormatted
 * @property {string} driver
 * @property {string} vehicle
 * @property {Array<import('@/components/common/DropList.vue').DropListValue>} tripsWithLinks
 */
</script>

<style lang="scss">
.duty-list {
  padding: $view-standard-padding;

  &__datepicker {
    display: none;
  }

  &__datepicker-btn {
    margin-left: 10px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 12px;
  }

  &__header-left {
    display: flex;
    align-items: center;
  }

  &__header-right {
    display: flex;

    .ui-btn {
      padding: 0 15px;
      line-height: 40px;
    }
  }

  &__no-data {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-top: 25vh;
    text-align: center;
  }

  &__no-data-buttons {
    display: flex;
  }

  &__no-data-text {
    margin-bottom: 20px;
  }

  .arrow-duty {
    margin: 0 8px;
    color: $text-neutral;
    font-size: 14px;
  }
}
</style>

<i18n locale="fr">
{
  "duties": " prise de service | prises de service",
  "dutyListDownloadName": " liste-prises-de-service_{0}.csv",
  "nextDay": "Jour suivant",
  "noDuties": "Pas de prise de service à cette date.",
  "previousDay": "Jour précédent",
  "sendMessage": "Envoyer un message",
  "over": "Terminé",
  "inProgress": "En cours",
  "cleaningness": {
    "dirty": "Sale",
    "average": "Moyen",
    "clean": "Propre",
  },
  "fuel": {
    "empty": "Vide",
    "low": "Bas",
    "high": "Haut",
    "full": "Plein"
  }
}
</i18n>

<i18n locale="en">
{
  "duties": " duties | duties",
  "dutyListDownloadName": "duty-list_{0}.csv",
  "nextDay": "Next day",
  "noDuties": "No duties on this date.",
  "previousDay": "Previous day",
  "sendMessage": "Send a message",
  "over": "Over",
  "inProgress": "In progress",
  "cleaningness": {
    "dirty": "Dirty",
    "average": "Average",
    "clean": "Clean",
  },
  "fuel": {
    "empty": "Empty",
    "low": "Low",
    "high": "High",
    "full": "Full"
  }
}
</i18n>
