<script setup lang="ts">
import api, { tripUpdates as ApiTripUpdates } from '@/api';
import MapboxTraffic, { type Options } from '@mapbox/mapbox-gl-traffic';

import MapboxDevices from '@/components/map/MapboxDevices.vue';
import {
  HighlightType,
  type DeviceAdditionalDatas,
  type DeviceFiltered,
  type LayerOptions,
  type MapStop,
  type MapTrip,
  type StopsOptions,
} from '@/@types/mapbox';
import { dateObjToGtfsFormat } from '@/libs/helpers/dates';
import { localeCompareSort } from '@/libs/helpers/strings';

import LiveMapDeviceList, { SortField } from './LiveMapDeviceList.vue';
import LiveMapDeviceFilter, { type FilterEvent } from './LiveMapDeviceFilter.vue';
import MapLayersDropdown from '@/components/map/MapLayersDropdown.vue';
import ModalMapOptions from './ModalMapOptions.vue';

import MapboxMap from '@/components/map/MapboxMap.vue';
import { MapboxHelper } from '@/components/map/mapboxHelper';
import { AlternativeState, deviceTeamsFilterFunction, OFF_ITINERARY } from '@/store/devices';
import { SortDirection, type SortFilter } from '@/components/common/SortFilterButtons.vue';
import { useVehiclesStore } from '@/store-pinia/vehicles';
import { computed, onMounted, ref, useTemplateRef, watch } from 'vue';
import { useStore } from 'vuex';
import type { Route, Stop, Trip } from '@/@types/gtfs';
import type { MapDepot } from '@/store-pinia/depots';
import type { Device } from '@/@types/device';
import type { DropdownOption } from '@/components/common/SelectFiltersDropdown.vue';
import { useI18n } from 'vue-i18n';
import { useTripUpdates, type ApiTripUpdate } from '@/store-pinia/trip-updates';

interface MapboxTrafficFull extends MapboxTraffic {
  options: Options;
}

const mapboxTrafficControl = new MapboxTraffic({ showTrafficButton: false }) as MapboxTrafficFull;
const DEFAULT_LAYERS = {
  vehicles: true,
  vehiclesLabels: false,
  stops: true,
  stopsZones: false,
  stations: true,
  traffic: false,
  linesShapes: true,
};

const vehiclesStore = useVehiclesStore();
const store = useStore();
const tripUpStore = useTripUpdates();
const i18n = useI18n();

const props = defineProps({
  date: {
    type: [String, Number, Date],
    default: null,
  },
  mapContainerHeight: {
    type: String,
    default: '100%',
  },
  minimalVersion: {
    type: Boolean,
    default: false,
  },
});

const formattedTripNames = ref<{ [deviceId: string]: string }>({});
const layers = ref<LayerOptions>(DEFAULT_LAYERS);
const mapBounds = ref<mapboxgl.LngLatBounds>();
const mapLoaded = ref<boolean>(false);
const modalShown = ref<boolean>(false);
const selectedDeviceId = ref<string>();
const mapInstance = ref<mapboxgl.Map>();
const searchedDevices = ref<Array<string>>();
const sortFilter = ref<SortFilter>();
const deviceListFormated = ref<Array<DeviceAdditionalDatas>>([]);
const mapDepots = ref<Array<MapDepot>>([]);
const filters = ref<FilterEvent>({
  routes: [],
  teams: [],
  categories: [],
});

const displayTripUpdateInfos = ref<boolean>(false);

const deactivatedRoutes = computed<string[]>(() => store.getters.group.deactivated_routes);
const devices = computed<{ [deviceId: string]: Device }>(() => store.getters['devices/visibleDevices']);
const groupId = computed<string>(() => store.getters.group._id);
const gtfsId = computed<string>(() => store.getters.group.current_file);
const gtfsRoutes = computed<{ [routeId: string]: Route }>(() =>
  store.getters['gtfs/getCachedGtfsTable'](gtfsId.value, 'routes'),
);
const gtfsStops = computed<{ [stopId: string]: Stop }>(() =>
  store.getters['gtfs/getCachedGtfsTable'](gtfsId.value, 'stops'),
);
const gtfsTrips = computed<{ [tripId: string]: Trip }>(() =>
  store.getters['gtfs/getCachedGtfsTable'](gtfsId.value, 'trips'),
);
const routesFilterOptions = computed<Array<DropdownOption>>(() => {
  const routeOptions = Object.values(gtfsRoutes.value).reduce((options: Array<DropdownOption>, route) => {
    options.push({
      label: route.route_short_name,
      id: route.route_id,
      isDeactivated: deactivatedRoutes.value.includes(route.route_id),
      routeProperties: {
        route_short_name: route.route_short_name,
        route_text_color: route.route_text_color,
        route_color: route.route_color,
      },
    });
    return options;
  }, []);
  return routeOptions.sort((a, b) =>
    localeCompareSort(a.label as string, b.label as string, i18n.locale.value),
  );
});

const lineFilterTripsIds = computed<string[]>(() => {
  const routesIds = filters.value.routes.reduce((ids: { [routeId: string]: boolean }, option) => {
    ids[option] = true;
    return ids;
  }, {});

  return Object.keys(gtfsTrips.value).filter(tripId => routesIds[gtfsTrips.value[tripId].route_id]);
});

const mapDevices = computed<Array<DeviceFiltered>>(() => {
  return sortedDevices.value.map(id => ({
    device: devices.value[id],
    id,
    highlight: id === selectedDeviceId.value,
    additionalDatas: deviceListFormated.value.find(d => d._id === id),
  }));
});

const mapStops = computed<Array<MapStop>>(() => {
  const selectedDevice = selectedDeviceId.value ? devices.value[selectedDeviceId.value] : null;

  // Filtered trips is based on selectedDevice, or  lineFilterTripsIds (from map filters)
  const displayedFilteredTrips =
    selectedDevice && selectedDevice.trip_id
      ? Object.keys(gtfsTrips.value).filter(tripId => tripId === selectedDevice.trip_id)
      : lineFilterTripsIds.value;

  const stopIds = Object.keys(
    // Get Stop Id from filtered Trips, or display every stop in gtfs
    displayedFilteredTrips && displayedFilteredTrips.length > 0
      ? displayedFilteredTrips.reduce((acc: { [stopId: string]: boolean }, tripId) => {
          gtfsTrips.value[tripId].stop_times.forEach(st => {
            acc[st.stop_id] = true;
          });
          return acc;
        }, {})
      : gtfsStops.value,
  );

  return stopIds.map(id => ({ id, highlight: false }));
});

const mapTrips = computed<Array<MapTrip>>(() => {
  if (!layers.value.linesShapes) return [];

  const tripIds =
    lineFilterTripsIds.value.length > 0 ? lineFilterTripsIds.value : Object.keys(gtfsTrips.value);

  const selectedDevice = selectedDeviceId.value ? devices.value[selectedDeviceId.value] : null;
  // If a device is selected, only display trip from this device
  if (selectedDevice && selectedDevice.trip_id != null) {
    return tripIds
      .filter(id => id === selectedDevice.trip_id)
      .map(id => ({
        id,
        highlight: HighlightType.MORE,
      }));
  }
  return tripIds.map(id => ({ id, highlight: HighlightType.NONE }));
});

const sortedDevices = computed<string[]>(() => {
  const deviceTeamsFilter =
    filters.value.teams?.length > 0 && filters.value.teams.length !== teams.value.length
      ? deviceTeamsFilterFunction(filters.value.teams)
      : undefined;

  const devicesIds = Object.keys(devices.value).filter(deviceId => {
    const device = devices.value[deviceId];

    if (
      lineFilterTripsIds.value?.length > 0 &&
      !lineFilterTripsIds.value.includes(device.trip_id as string)
    ) {
      return false;
    }

    if (deviceTeamsFilter && !deviceTeamsFilter(device)) {
      return false;
    }

    // handle delay state
    let delayCategory = device.delay != null ? store.getters['devices/getDelayState'](device.delay) : null;

    // handle dead running
    if (device.trip_id == null) delayCategory = AlternativeState.DEAD_RUNNING;
    // handle routing
    if (device.trip_pending) delayCategory = AlternativeState.ROUTING;

    if (device.off_itinerary) delayCategory = OFF_ITINERARY;

    if (filters.value.categories?.length > 0) {
      if (!filters.value.categories.includes(delayCategory)) {
        return false;
      }
    }
    if (searchedDevices.value) {
      if (searchedDevices.value.length === 0 || !searchedDevices.value.includes(deviceId)) return false;
    }
    return true;
  });

  const collator = new Intl.Collator(i18n.locale.value, {
    numeric: true,
  });

  const isOnline = (id: string): boolean => !!store.state.devices.online[id];
  const isOnTrip = (id: string): boolean => !!devices.value[id].trip_id;

  const getRouteName = (id: string): string => {
    const tripId = devices.value[id].trip_id || '';
    const route = gtfsRoutes.value[(gtfsTrips.value[tripId] || {}).route_id] || {};
    return route.route_short_name || route.route_long_name;
  };

  const getDelay = (id: string): number => {
    let delay = devices.value[id].delay;
    // Handle case when delay not set, those values must stay last in order sort
    if (delay === null || delay == undefined)
      if (sortFilter.value?.direction)
        sortFilter.value.direction === SortDirection.ASC ? (delay = Infinity) : (delay = -Infinity);
      else delay = Infinity;
    return delay;
  };

  const getVehicleLoad = (id: string): number => devices.value[id]?.vehicle_load || 0;
  const compareBoolean = (a: boolean, b: boolean): number => (a === b ? 0 : a ? -1 : 1);
  const compareNumber = (a: number, b: number): number => (a < b ? -1 : 1);

  const orderByFilter = (a: string, b: string): number => {
    const firstValue = !sortFilter.value || sortFilter.value.direction === SortDirection.ASC ? a : b;
    const secondValue = firstValue !== a ? a : b;
    switch (sortFilter.value?.field?.id) {
      case SortField.PASSENGER_COUNT:
        return compareNumber(getVehicleLoad(firstValue), getVehicleLoad(secondValue));
      case SortField.DELAY:
        return compareNumber(getDelay(firstValue), getDelay(secondValue));
      case SortField.ROUTE:
      default:
        return collator.compare(getRouteName(firstValue), getRouteName(secondValue));
    }
  };

  devicesIds.sort(
    (a, b) =>
      compareBoolean(isOnline(a), isOnline(b)) ||
      compareBoolean(isOnTrip(a), isOnTrip(b)) ||
      orderByFilter(a, b) ||
      collator.compare(formattedTripNames.value[a], formattedTripNames.value[b]),
  );

  return devicesIds;
});

const stopsOptions = computed<Partial<StopsOptions>>(() => {
  return {
    stationsMarkers: layers.value.stations,
    stopsMarkers: layers.value.stops,
    stopsZones: layers.value.stopsZones,
    showUnserved: true,
  };
});

const teams = computed<Array<DropdownOption>>(() => {
  return [
    { id: '', label: i18n.t('noTeam') },
    ...store.getters.activeTeams.map(({ team_id, name }: { team_id: string; name: string }) => ({
      id: team_id,
      label: name ?? team_id,
    })),
  ];
});

watch(
  () => groupId.value,
  async () => {
    loadDrivers();
    await loadVehicles();
    loadActivityLog();
  },
);
watch(
  () => gtfsId.value,
  () => {
    fetchRoutes();
    fetchStops();
    fetchTrips();
  },
  { immediate: true },
);

watch(
  () => layers.value,
  () => {
    layersLSSave();
  },
  { deep: true },
);

watch(
  () => layers.value.traffic,
  () => {
    if (!mapLoaded.value) return;

    if (layers.value.traffic !== mapboxTrafficControl.options.showTraffic) {
      mapboxTrafficControl.toggleTraffic();
    }
  },
);

watch(
  () => selectedDeviceId.value,
  async () => {
    if (!mapInstance.value) return;
    if (selectedDeviceId.value !== undefined) {
      const mapDevice = mapDevices.value.find(d => d.id === selectedDeviceId.value);
      if (mapDevice) {
        mapInstance.value.setZoom(14);
        if (mapDevice.device.latlng)
          mapInstance.value.panTo([mapDevice.device.latlng[1], mapDevice.device.latlng[0]]);

        if (mapDevice.device.trip) {
          try {
            const tripUpdate = await ApiTripUpdates.getTripUpdatesByTrip(
              groupId.value,
              mapDevice.device.trip!.start_date,
              mapDevice.device.trip!.gtfs_id,
              mapDevice.device.trip!.trip_id,
            );
            if (tripUpdate) {
              tripUpStore.initStore(tripUpdate.gtfs_id, tripUpdate.trip_id, tripUpdate);
              displayTripUpdateInfos.value = true;
            } else resetTripUpdateDisplay();
          } catch (error) {
            resetTripUpdateDisplay();
          }
        }
      }
    } else {
      resetTripUpdateDisplay();
      if (mapBounds.value)
        mapInstance.value.fitBounds(mapBounds.value, {
          padding: 40,
          animate: false,
        });
    }
  },
);

function resetTripUpdateDisplay() {
  tripUpStore.$reset();
  displayTripUpdateInfos.value = false;
}

onMounted(async () => {
  layersLSLoad();
  loadDrivers();
  await loadVehicles();
  loadActivityLog();
  loadDepots();

  const liveMap = document.getElementById('live-map');
  liveMap?.addEventListener('click', evt => removeTooltipOnClickOutsideMap(evt));
});
async function fetchRoutes() {
  await store.dispatch('gtfs/getRoutesMap', { gtfsId: gtfsId.value });
}

async function fetchStops() {
  await store.dispatch('gtfs/getStopsMap', { gtfsId: gtfsId.value });
}

async function fetchTrips() {
  await store.dispatch('gtfs/getTripsMap', { gtfsId: gtfsId.value });
}
/**
 * Remove potential tooltips on click outside mapbox
 */
function removeTooltipOnClickOutsideMap(evt: MouseEvent) {
  const mapboxGlCanvas = document.getElementsByClassName('mapboxgl-canvas')[0];
  if (evt.target !== mapboxGlCanvas && mapInstance.value) MapboxHelper.removeAllTooltips(mapInstance.value);
}

/**
 * Load layers from localStorage.
 */
function layersLSLoad() {
  const savedOptions = MapboxHelper.getLayersOptionsFromLS('settings.op.liveMap.map.layerOptions');
  if (savedOptions) {
    // remove deprecated keys from localStorage :
    Object.keys(savedOptions).forEach(key => {
      if (!Object.keys(DEFAULT_LAYERS).includes(key)) {
        delete savedOptions[key as keyof LayerOptions];
      }
    });
    layers.value = savedOptions;
  }
}

/**
 * Save layers to localStorage.
 */
function layersLSSave() {
  MapboxHelper.saveLayerOptionstoLS('settings.op.liveMap.map.layerOptions', layers.value);
}

function loadActivityLog() {
  store.dispatch(
    'activityLog/loadEntries',
    dateObjToGtfsFormat(props.date ? new Date(props.date) : new Date()),
  );
}

/**
 * Load drivers list for assigned values.
 */
function loadDrivers() {
  store.dispatch('drivers/loadList');
}

/**
 * Load vehicles list for assigned values.
 */
async function loadVehicles() {
  await vehiclesStore.loadList();
}

function onDevicesMouseEnter() {
  if (mapInstance.value) mapInstance.value.getCanvasContainer().style.cursor = 'pointer';
}

function onDevicesMouseLeave() {
  if (mapInstance.value) mapInstance.value.getCanvasContainer().style.cursor = '';
}

function onMapClick(event: mapboxgl.MapLayerMouseEvent) {
  const feature = event.features ? event.features[0] : null;
  if (feature) {
    selectedDeviceId.value = feature.properties?.id;
  } else {
    selectedDeviceId.value = undefined;
  }
}

function onMapLoad({ map }: { map: mapboxgl.Map }) {
  map.addControl(mapboxTrafficControl);

  map.once('idle', () => {
    mapInstance.value = map;
    mapLoaded.value = true;

    if (layers.value.traffic !== mapboxTrafficControl.options.showTraffic) {
      mapboxTrafficControl.toggleTraffic();
    } else {
      mapboxTrafficControl.render();
    }
  });
}
/**
 * update filter Object
 */
function handleFilters(newFilters: FilterEvent) {
  filters.value = newFilters;
  selectedDeviceId.value = undefined;
}
function updateSearchedDevices(devices: Array<string>) {
  selectedDeviceId.value = undefined;
  searchedDevices.value = devices;
}
function updateSortFilter(sortingFilter: SortFilter) {
  sortFilter.value = sortingFilter;
}
function updateDeviceListFormated(deviceList: Array<DeviceAdditionalDatas>) {
  deviceListFormated.value = deviceList;
}

const mapboxMapComponent = useTemplateRef('mapboxMapComponent');

function switchMapStyle(style: string) {
  mapboxMapComponent.value?.switchStyle(style);
}

async function loadDepots() {
  const depots = await api.depot.getDepots(groupId.value);
  // Convert Depot to MapDepot
  mapDepots.value = depots.map(depot => ({
    id: depot.id,
    latitude: depot.location.latitude,
    longitude: depot.location.longitude,
    radius: depot.radius,
    name: depot.name,
  }));
}
</script>

<template>
  <div id="live-map" class="live-map">
    <div v-show="!minimalVersion" class="live-map__devices">
      <LiveMapDeviceList
        v-model:selected-device-id="selectedDeviceId"
        :sorted-devices="sortedDevices"
        @searchFilterList="updateSearchedDevices"
        @updateSortFilter="updateSortFilter"
        @deviceListFormated="updateDeviceListFormated"
      />
    </div>
    <div class="live-map__right-side" :style="`height: ${mapContainerHeight}`">
      <LiveMapDeviceFilter
        v-show="!minimalVersion"
        class="live-map__filters"
        :teams-filter-options="teams"
        :routes-filter-options="routesFilterOptions"
        @updateFilters="handleFilters"
      />

      <div class="live-map__map" :class="{ 'live-map__map__no-margin': minimalVersion }">
        <div v-show="!minimalVersion" class="live-map__map__layers">
          <MapLayersDropdown v-model="layers" @openModal="modalShown = true" @switchStyle="switchMapStyle" />
        </div>

        <MapboxMap
          ref="mapboxMapComponent"
          v-model:bounds="mapBounds"
          :full-screen-option="minimalVersion ? false : true"
          :gtfs-id="gtfsId"
          :display-tooltip="true"
          :stops="mapStops"
          :depots="mapDepots"
          :stops-options="stopsOptions"
          :trip-update-mode="displayTripUpdateInfos"
          :trips="mapTrips"
          view-name="liveMap"
          @click="onMapClick"
          @click:device="onMapClick"
          @load="onMapLoad"
        >
          <MapboxDevices
            v-if="mapInstance"
            :devices="mapDevices"
            :gtfs-id="gtfsId"
            :map="mapInstance"
            :show-labels="layers.vehiclesLabels"
            :display-tooltip="true"
            @click="e => mapboxMapComponent?.onMapClick(e, 'device')"
            @mouseenter="onDevicesMouseEnter"
            @mouseleave="onDevicesMouseLeave"
          />
        </MapboxMap>
      </div>
    </div>
    <ModalMapOptions
      v-if="modalShown"
      v-model:keep-display-vehicle-label="layers['vehiclesLabels']"
      @close="modalShown = false"
    />
  </div>
</template>

<style lang="scss">
.live-map {
  display: flex;
  flex-direction: row;
  height: 100%;
  background-color: $canvas;

  @media (max-width: 1200px) {
    flex-direction: column-reverse;
  }

  &__devices {
    display: flex;
    flex: 1;
    min-width: 400px;

    @media (max-width: 500px) {
      min-width: 0;
      height: 50%;
    }

    @media (max-width: 1200px) {
      height: 50%;
    }
  }

  &__right-side {
    display: flex;
    flex: 3;
    flex-direction: column;
  }

  &__filters {
    flex: 0 1 auto;
    justify-content: right;
    border-bottom: $light-border;

    @media (max-width: 1200px) {
      justify-content: start;
      margin-left: $view-standard-padding;
    }
  }

  &__map {
    position: relative;
    flex: 1 1 auto;
    height: 100%;

    &__layers {
      position: relative;
      z-index: $map-layers-index;
      margin: 16px;
    }

    &__no-margin {
      margin: 0;
    }
  }
}
</style>

<i18n locale="fr">
{
  "filter": "Filtre",
  "previousMap": "Interface précédente",
  "noTeam": "Sans équipe",

}
</i18n>

<i18n locale="en">
{
  "filter": "Filter",
  "previousMap": "Previous map",
  "noTeam": "No team",

}
</i18n>
