import type { Route, StopTime } from '@/@types/gtfs';
import { FstType } from '@/store-pinia/trip-updates';
import type { Device } from './device';

export const SHAPES_ARROW_LAYER_ID = 'shapesArrowLayer';
export const SHAPES_HIGHLIGHT_FG_LAYER_ID = 'shapesHighlightFgLayer';
export const SHAPES_HIGHLIGHT_BG_LAYER_ID = 'shapesHighlightBgLayer';
export const SHAPES_FG_LAYER_ID = 'shapesFgLayer';
export const SHAPES_DASH_ARRAY_LAYER_ID = 'shapesDashArrayLayer';
export const SHAPES_BG_LAYER_ID = 'shapesBgLayer';

export const STATIONS_ICON_LAYER_ID = 'stationsIconLayer';
export const STOPS_ICON_LAYER_ID = 'stopsIconLayer';
export const STOPS_ZONE_LAYER_ID = 'stopsZoneLayer';

export const DEPOTS_SOURCE_ID = 'depotsSource';
export const DEPOTS_ZONE_SOURCE_ID = 'depotsZoneSource';
export const DEPOTS_ICON_LAYER_ID = 'depotsIconLayer';
export const DEPOTS_ICON_TEXT_LAYER_ID = 'depotsIconTextLayer';
export const DEPOTS_ZONE_LAYER_ID = 'depotsZoneLayer';

export const UNSERVED_STOP_LAYER = 'unservedStopLayer';
export const UNSERVED_STATION_LAYER = 'unservedStationLayer';
export const DEVIATION_STOP_LAYER = 'deviationStopLayer';
export const DEVIATION_STATION_LAYER = 'deviationStationLayer';

export enum HighlightType {
  NONE = 0,
  LESS = 1,
  MORE = 2,
}

export interface MapTrip {
  id: string;
  highlight: HighlightType;
}

export interface ShapeData {
  color: string;
  geometry: GeoJSON.LineString;
  id: string;
}

export interface MapStop {
  id: string;
  highlight: boolean;
  unserved?: boolean;
  stop_sequence?: number;
  deviation?: boolean;
  editionHighlight?: boolean;
  type?: FstType;
}

export interface StopsOptions {
  stopsMarkers: boolean;
  stopsZones: boolean;
  stationsMarkers: boolean;
  stopsBigMarkers: boolean;
  showUnserved: boolean;
  stopSelectorData?: StopTime[];
}

export interface DisplayOptions {
  linesShapes: boolean;
  stopsZones: boolean;
  traffic: boolean;
  vehicles: boolean;
  stations: boolean;
  stops: boolean;
}

export interface LayerOptions extends DisplayOptions {
  vehiclesLabels: boolean;
}

export interface MapDevice {
  device: Device;
  id: string;
  highlight: boolean;
}

export interface DeviceFiltered extends MapDevice {
  additionalDatas?: DeviceAdditionalDatas;
}

export interface DeviceAdditionalDatas {
  route: Route;
  driver: string;
  vehicle: string;
  formattedTripName: string;
  _id: string;
}

export interface MapboxDirectionsAPI {
  code: string;
  uuid: string;
  waypoints: {
    distance: number;
    name: string;
    location: [number, number];
  }[];
  routes: {
    distance: number;
    duration: number;
    geometry: {
      coordinates: [number, number][];
      type: string;
    };
    legs: {
      via_waypoints: [];
      admins: {
        iso_3166_1: string;
        iso_3166_1_alpha3: string;
      }[];
      distance: number;
      duration: number;
      steps: [];
      summary: string;
      weight: number;
    }[];
    weight: number;
    weight_name: string;
  }[];
}
