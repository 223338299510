import Maintenance from '@/pages/MaintenancePage/index.vue';
import { GroupRoute, HomeRoute } from './routing';
import type { Router } from 'vue-router';
import type { Plugin } from 'vue';

const STATUS_CODE = [502, 503];
const POLL_INTERVAL = 1000;
const ROUTER_NAME = HomeRoute.MAINTENANCE;
const ROUTER_PATH = '/maintenance';

interface Maintenance {
  check(response: Response): boolean;
  poll(): void;
  goTo(): void;
}
const maintenance: Maintenance = {
  check: () => false,
  poll: () => null,
  goTo: () => null,
};

/**
 * Check if API response has a maintenance status code
 * @param {Response} response
 * @return {boolean}
 */
maintenance.check = response => STATUS_CODE.includes(response?.status);

/**
 * Poll API
 * @return {void}
 */
maintenance.poll = () => null;

/**
 * Go to maintenance
 * @return {void}
 */
maintenance.goTo = () => null;

export default {
  /**
   * Maintenance install
   *
   * @param {import('vue').VueConstructor} Vue
   * @param {Object} options
   * @param {import('vue-router').default} options.router - Vue router instance
   * @param {() => Promise<Response>} [options.poll] - Poll function
   */
  install(Vue: any, options: { router: Router; poll?: () => Promise<Response> }) {
    const { router, poll } = options;

    if (!router) throw new Error('router is required');

    // Check if maintenance route is the router current route
    const isCurrentRoute = () => router.currentRoute.value.name === ROUTER_NAME;

    // Poll API
    maintenance.poll = () => {
      if (!poll) return;

      // eslint-disable-next-line consistent-return
      const interval = setInterval(async () => {
        // Do not poll if maintenance is not the current route
        if (!isCurrentRoute()) return clearInterval(interval);

        // Replace maintenance route with original route if maintenance is over
        if (!maintenance.check(await poll())) {
          const lastGroup = localStorage.getItem('settings.op.lastGroupId');
          if (lastGroup)
            router.push({
              name: GroupRoute.TRIP_LIST,
              params: {
                groupId: lastGroup,
              },
            });
          else router.back();
        }
      }, POLL_INTERVAL);
    };

    // Inject route into vue router instance
    const route = {
      component: Maintenance,
      name: ROUTER_NAME,
      path: ROUTER_PATH,
    };

    // addRoutes is now deprecrated, use it if the version of Vue Router does not provide addRoute method
    router.addRoute ? router.addRoute(route) : router.addRoute(route);

    // Go to maintenance
    maintenance.goTo = () => {
      if (isCurrentRoute()) return;

      // Push maintenance route
      router.push({ name: ROUTER_NAME });
    };
  },
} as Plugin;

export { maintenance };
