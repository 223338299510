// Port of @pysae-driver/core request function with error handling.

import { baseApiUrl } from '@/api';
import { version } from '@/version';

export class InvalidResponseError<T = unknown> extends Error {
  constructor(
    message: string,
    public readonly data: T,
    public readonly response: Response,
  ) {
    super(message);
  }
}

export class PysaeApiErrorContent {
  constructor(
    public readonly code: string,
    public readonly payload: object,
  ) {}
}

export class PysaeApiError extends InvalidResponseError {}

export async function request(uri: string, options?: RequestInit): Promise<Response> {
  options = options ?? {};
  options.headers = {
    ...(options.headers ?? {}),
    'Api-User-Agent': `Op/${version}`,
  };

  const url = uri.startsWith('http') ? uri : `${baseApiUrl}${uri}`;
  const response = await fetch(url, options);

  if (!response.ok) {
    let data: unknown;
    try {
      data = await response.json();
    } catch {
      // do nothing
    }

    const method = options?.method ?? 'GET';
    const pysaeContentCandidate = data as Partial<PysaeApiErrorContent> | undefined | null;
    if (pysaeContentCandidate && pysaeContentCandidate.code && pysaeContentCandidate.payload) {
      throw new PysaeApiError(
        `Error status for ${method} ${url} (${response.status})`,
        new PysaeApiErrorContent(pysaeContentCandidate.code, pysaeContentCandidate.payload),
        response,
      );
    }

    throw new InvalidResponseError(`Error status for ${method} ${url} (${response.status})`, data, response);
  }

  return response;
}
